import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'
import { Helmet } from 'react-helmet'

import { emoji_style } from '../style/emoji_style'

import {
  common_color,
  text_space_max_width,
  media_mobile,
} from '../style/common_value'

const not_found_style = css(emoji_style, {
  margin: '8rem auto 0',
  // margin: '0 auto',
  padding: '0 1rem',
  fontSize: '1.3rem',
  maxWidth: text_space_max_width,
  h1: {
    fontSize: '1.5rem',
    [media_mobile.tab]: {
      fontSize: '1.5rem',
    },
  },
})

const big_font = css({
  fontSize: '9rem',
  [media_mobile.tab]: {
    fontSize: '13rem',
  },
})

const text = css({
  marginTop: '2rem',
  lineHeight: '2.5rem',
})

const go_top = css({
  marginTop: '4rem',
  color: common_color.link,
  textDecoration: 'underline',
})

const NotFoundPage: React.FC = () => (
  <>
    <Helmet>
      <title>ページが存在しません | アバラバクハツ</title>
      <meta name="robots" content="noindex,nofollow" />
      <meta name="googlebot" content="noindex,nofollow" />
    </Helmet>
    <div id="not_found">
      <div css={not_found_style}>
        <h1>
          <span css={big_font}>404</span> NOT FOUND
        </h1>
        <div css={text}>
          <p>
            Oops, Sorry!
            <br />
            このページは存在しないようです……
            <span className="wavy_mouth_emoji">Woozy Face Emoji</span>
          </p>
          <p css={go_top}>
            <Link to="/">トップページへ移動</Link>
          </p>
        </div>
      </div>
    </div>
  </>
)

export default NotFoundPage
